import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import TableHead from '../../../atoms/share/TableHead'
import TableBody from '../../../atoms/share/TableBody'
import TableBodyGrid from '../../../atoms/share/TableBodyGrid'
import ButtonRed from '../../../atoms/share/button/ButtonRed';
import { fetchEmployees, deleteEmployee } from '../../../../api/employee'
import ButtonThirdly from '../../../atoms/share/button/ButtonThirdly';

export const StaffList = () => {
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    fetchEmployees()
      .then((res) => {
        setEmployees(res.data)
      })
      .catch(() => {
        console.log('通信に失敗しました');
      });
  }, []);

  const heads = ['氏名', '部署', '雇用形態', '月次勤務時間', '残業時間', '超過労働時間', '']

  const handleDelete = (id: number) => {
    const confirmed = window.confirm('従業員を削除します。よろしいですか？');

    if (confirmed) {
      deleteEmployee(id)
        .then((res) => {
          setEmployees(
            employees.filter(item =>
              item.id !== res.data.id
            )
          )
        })
        .catch(() => {
          window.alert('通信に失敗しました')
        });
    }
  }

  return (
    <StaffListArea>
      <Table>
        <TableHead items={heads} />
        <Tbody>
          {employees.map((employee, index) => (
            <Row key={index}>
              <TableBody>{employee.name}</TableBody>
              <TableBody>{employee.department_name}</TableBody>
              <TableBody>{employee.employment_status_name}</TableBody>
              <TableBody>{}</TableBody>
              <TableBody>{}</TableBody>
              <TableBody>{}</TableBody>
              <TableBodyGrid>
                <TableButtonArea>
                  <ButtonThirdly path={`/management/${employee.id}`} text='出勤表' />
                </TableButtonArea>
                <TableButtonArea>
                  <ButtonThirdly path={`/management/staff/${employee.id}/edit`} text='編集' />
                </TableButtonArea>
                <TableButtonArea>
                  <ButtonRed text='削除' onClick={() => handleDelete(employee.id)} />
                </TableButtonArea>
              </TableBodyGrid>
            </Row>
          ))}
        </Tbody>
      </Table>
    </StaffListArea>
  )
}

const StaffListArea = styled.div`
`
const Table = styled.table`
    border-collapse: collapse;
    margin: 0 auto;
    width: 100%;
    table-layout: auto;
`
const Tbody = styled.tbody`
    display: contents;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 300px;
    border-bottom: 1px solid #000000;
`
const Row = styled.tr`
  border-bottom: 1px solid #808080;
`
const TableButtonArea = styled.span`
    display: block;
    text-align: center;
`

  export default StaffList
