import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import Select from 'react-select'
import TableHead from '../../../atoms/share/TableHead'
import TableBody from '../../../atoms/share/TableBody'
import { years, months } from '../../../../modules/date'
import { fetchOverWorkRequests, deleteOverWorkRequest } from '../../../../api/over_work_request'
import ButtonSecondary from '../../../atoms/share/button/ButtonSecondary';
import ButtonRed from '../../../atoms/share/button/ButtonRed';
import ButtonPrimary from '../../../atoms/share/button/ButtonPrimary';

const heads = ['申請日', '希望日', '承認状況', '残業理由', '']

export const OverWorkRequestList = () => {
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [overWorkRequests, setOverWorkRequests] = useState([])
  useEffect(() => {
    fetchOverWorkRequests()
      .then((res) => {
        setOverWorkRequests(res.data)
      })
      .catch(() => {
        window.alert('通信に失敗しました')
      })
  }, []);

  const handleSubmit = () => {
    fetchOverWorkRequests(selectedYear.value, selectedMonth.value)
      .then((res) => {
        setOverWorkRequests(res.data)
      })
      .catch(() => {
        window.alert('通信に失敗しました')
      })
  }

  const handleDelete = (id: number) => {
    const confirmed = window.confirm('残業申請を削除します。よろしいですか？');

    if (confirmed) {
      deleteOverWorkRequest(id)
        .then((res) => {
          setOverWorkRequests(
            overWorkRequests.filter(item =>
              item.id !== res.data.id
            )
          )
        })
        .catch(() => {
          window.alert('通信に失敗しました')
        })
    }
  }

  return (
    <OverWorkRequestListArea>
      <Top>
        <Section>
          <SelectBox><Select value={selectedYear} onChange={setSelectedYear} options={years} />年</SelectBox>
          <SelectBox><Select value={selectedMonth} onChange={setSelectedMonth} options={months} />月</SelectBox>
          <ButtonBox>
            <ButtonSecondary text='表示する' onClick={handleSubmit} disabled={!selectedYear || !selectedMonth} />
          </ButtonBox>
        </Section>
        <Section>
          <ButtonPrimary path='/employees/requests/over_work' text='新規申請' />
        </Section>
      </Top>
      <Table>
        <TableHead items={heads} />
        <Tbody>
          {overWorkRequests.map((overWorkRequest, index) => (
            <Row key={index}>
              <TableBody>{overWorkRequest.created_at}</TableBody>
              <TableBody>{overWorkRequest.date}</TableBody>
              <TableBody>{overWorkRequest.status}</TableBody>
              <TableBody>{overWorkRequest.reason}</TableBody>
              <TableBody>
                <SecondaryButtonAlign>
                  <ButtonRed
                    text="削除"
                    onClick={() => handleDelete(overWorkRequest.id)}
                    disabled={overWorkRequest.status !== '未承認'}
                  />
                </SecondaryButtonAlign>
              </TableBody>
            </Row>
          ))}
        </Tbody>
      </Table>
    </OverWorkRequestListArea>
  )
}

const OverWorkRequestListArea = styled.div`
  font-size: 16px;
`
const Top = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 80%;
  margin:0 0 30px;
`
const Section = styled.div`
  display: flex;
`
const SelectBox = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`
const ButtonBox = styled.div`
  margin: 0 0 0 50px;
`
const Table = styled.table`
  border-collapse: collapse;
  width: 80%;
  table-layout: fixed;
`
const Tbody = styled.tbody`
  display: contents;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 300px;
`
const Row = styled.tr`
`
const SecondaryButtonAlign = styled.div`
  text-align: right;
`

export default OverWorkRequestList
