import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import Select from 'react-select'
import Title from '../../../atoms/share/Title'
import TextForm from '../../../atoms/TextForm'
import SubmitButton from '../../../atoms/SubmitButton'
import { createCompany } from '../../../../api/company'

export const Signup = () => {
  type Properties = {
    id: string, name: string
  }
  const [name, setName] = useState("")
  const [staffName, setStaffName] = useState("")
  const [email, setEmail] = useState("")
  const [cell, setCell] = useState("")
  const [password, setPassword] = useState("")
  const [plan, setPlan] = useState(1)
  const [hasOption, setHasOption] = useState(true)
  const [loading, setLoading] = useState(false)
  const [completeSignup, setCompleteSignup] = useState(false)


  const handleSubmit = () => {
    setLoading(true)
    createCompany(name, staffName, email, cell, password, plan, hasOption)
      .then(() => {
        initialForm()
        setLoading(false)
        setCompleteSignup(true)
      })
      .catch(() => {
        setLoading(false)
        console.log('通信に失敗しました')
        window.alert('通信に失敗しました')
      })
  }

  const initialForm = () => {
    setName("")
    setStaffName("")
    setEmail("")
    setCell("")
    setPassword("")
    setPlan(1)
    setHasOption(true)
  }

  const handleChange = e => {
    if (Boolean(hasOption) === true) {
      setHasOption(false);
    } else {
      setHasOption(e.target.value);
    }
  };

  const noticeComplete = () => {
    if (completeSignup) {
      return (
        <p>登録が完了しました</p>
      )
    }
  }

  return (
    <SignupArea>
      <Title value="管理者アカウント新規登録" />
      <Form>
        <Notification>
          {noticeComplete()}
        </Notification>
        <Item>
          <Label>事業者名</Label>
          <TextForm type="text" placeholder="株式会社勤怠" value={name} onChange={(e) => setName(e.target.value)} />
        </Item>
        <Item>
          <Label>代表者名</Label>
          <TextForm type="text" placeholder="山田太郎" value={staffName} onChange={(e) => setStaffName(e.target.value)} />
        </Item>
        <Item>
          <Label>電話番号</Label>
          <TextForm type="tel" placeholder="01234567890" value={cell} onChange={(e) => setCell(e.target.value)} />
        </Item>
        <Item>
          <Label>メールアドレス</Label>
          <TextForm type="email" placeholder="mail@example.com" value={email} onChange={(e) => setEmail(e.target.value)} />
        </Item>
        <Item>
          <Label>パスワード</Label>
          <TextForm type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </Item>
        <RegistrationButton>
          <SubmitButton
            text="新規登録する"
            onClick={handleSubmit}
            disabled={!name || !staffName || !email || !password || !plan || loading}
          />
        </RegistrationButton>
      </Form>
    </SignupArea>
  )
}

const SignupArea = styled.div`
  font-family: sans-serif,'Noto Sans JP';
`

const Form = styled.form`
  margin: 76px 100px 0;
  font-size: 16px;
`
const Notification = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #06d6a0;
`
const Item = styled.div`
  align-items: center;
  display: flex;
  line-height: 43px;
  list-style: none;
  margin-bottom: 24px;
`
const Label = styled.label`
  color: #707071;
  text-align: center;
  width: 30%;
`
const SelectBox = styled.div`
  display: flex;
  margin: 0 5px;
  align-items: center;
`
const RegistrationButton = styled.div`
  width: 100%;
  text-align: center;
  margin: 75px 0 0 0;
`
export default Signup
