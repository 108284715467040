import React from 'react';
import styled from 'styled-components';
import CompaniesList from '../../../organisms/management/companies/CompaniesList';
import Title from '../../../atoms/share/Title';

export interface IndexProps {
  isAdmin: number;
  currentCompanyId: number;
}

export const Index: React.FC<IndexProps> = ({ isAdmin, currentCompanyId }) => {
  return (
    <IndexArea>
      <Title value='管理者アカウント一覧' />
      {isAdmin !== 1 ? (
        <IndexArea>
          Permission Denied. <br />
          操作権限がありません。
        </IndexArea>
      ) : (
        <IndexArea>
            <CompaniesList currentCompanyId={currentCompanyId} />
        </IndexArea>
      )}
    </IndexArea>
  )
};

const IndexArea = styled.div``;

export default Index
