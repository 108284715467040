import React from 'react'
import styled from 'styled-components'
import Logo from '../../molecules/share/Logo'

export const Footer = () => {
  return (
    <FooterArea>
      <ContentArea>
        <FlexArea>
          <Logo />
          <CompanyName>©︎ 2024 Crossborder</CompanyName>
        </FlexArea>
      </ContentArea>
    </FooterArea>
  )
}

const FooterArea = styled.footer`
  width: 100%;
  height: 7vh;
  background-color:  #ffffff;
  position: relative;
  bottom: 0;
`
const ContentArea = styled.div`
  width: 100%;
  height: 7vh;
  color: #000000;
  font-size: 20px;
`
const FlexArea = styled.div`
  display: flex;
  height: 7vh;
  justify-content: space-between;
  margin: 0 3rem 0 2rem;
`
const Title = styled.h1`
  font-size: 20px;
  margin: auto 0;
  font-family: 'Pirulen', sans-serif, 'Noto Sans JP';
`
const CompanyName = styled.div`
  font-size: 16px;
  margin: auto 0;
  display: flex;
`

export default Footer
