import React from 'react';
import styled from 'styled-components'
import Logo from '../../molecules/share/Logo'
import SideItem from '../../molecules/share/SideItem'
import SideLink from '../../molecules/share/SideLink'
import { signOutCompany } from '../../../api/company'

export const AttendanceItems = [
  { name: '勤務状況', path: '/management' },
  { name: 'CSVダウンロード', path: '/management/csv_download' }
]
export const StaffItems = [
  { name: '従業員一覧', path: '/management/staff' },
  { name: '従業員新規登録', path: '/management/staff/new' }
]
export const SettingItems = [
  { name: '部署新規登録', path: '/management/departments/new' },
  { name: '雇用形態新規登録', path: '/management/employment_statuses/new' },
  { name: '所定・残業時間設定', path: '/management/working_times' },
  { name: '自動休憩設定', path: '/management/break_times' }
]

const signOut = () => {
  signOutCompany()
    .then(() => {
      document.location.href = '/'
    })
    .catch((res) => {
      window.alert(res.response.data.message)
    })
}

export interface SideListProps {
  isAdmin: number;
}

export const SideList: React.FC<SideListProps> = ({ isAdmin }) => {
  return (
    <SideListArea>
      <Logo />
      <TopSideItems>
        <SideItem title='出勤状況' items={AttendanceItems} />
        <SideLink title='休暇・申請管理' path='/management/requests' />
        <SideItem title='従業員管理' items={StaffItems} />
        <SideItem title='基本情報設定' items={SettingItems} />
        {isAdmin === 1 && (
          <SideLink title='管理者アカウント管理' path='/management/companies' />
        )}
        <Logout onClick={signOut}>ログアウト</Logout>
      </TopSideItems>
    </SideListArea>
  )
}

const SideListArea = styled.div`
  background: #F0F0F0;
  min-height: 800px;
  height: 100%;
  width: 15vw;
  padding: 1.5rem 20px;
  box-sizing: border-box;
  font-family: sans-serif, 'Noto Sans JP';
  font-weight: bold;
`
const Logout = styled.p`
  cursor: pointer;
`

const TopSideItems = styled.div`
  margin: 75px 0 0 0;
`

export default SideList