import React from 'react';
import styled from 'styled-components'
import EditForm from '../../../organisms/management/companies/EditForm'
import { EditableCompanyParams } from "../../../../types/company";

interface EditProps {
  company: EditableCompanyParams;
}

export const Edit = (props: EditProps) => {
  return (
    <EditArea>
      <EditForm company={props.company} />
    </EditArea>
  )
}

const EditArea = styled.div``;

export default Edit
