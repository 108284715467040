import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import Select from 'react-select'
import TableHead from '../../../atoms/share/TableHead'
import TableBody from '../../../atoms/share/TableBody'
import { years, months } from '../../../../modules/date'
import { fetchHolidayRequests, deleteHolidayRequest } from '../../../../api/holiday_request'
import ButtonSecondary from '../../../atoms/share/button/ButtonSecondary';
import ButtonRed from '../../../atoms/share/button/ButtonRed';
import ButtonPrimary from '../../../atoms/share/button/ButtonPrimary';

const heads = ['申請日', '希望日', '承認状況', '申請内容', '休暇理由', '']

export const HolidayRequestList = () => {
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [holidayRequests, setHolidayRequests] = useState([])
  useEffect(() => {
    fetchHolidayRequests()
      .then((res) => {
        setHolidayRequests(res.data)
      })
      .catch(() => {
        window.alert('通信に失敗しました')
      })
  }, []);

  const handleSubmit = () => {
    fetchHolidayRequests(selectedYear.value, selectedMonth.value)
      .then((res) => {
        setHolidayRequests(res.data)
      })
      .catch(() => {
        window.alert('通信に失敗しました')
      })
  }

  const handleDelete = (id: number) => {
    const confirmed = window.confirm('休暇申請を削除します。よろしいですか？');

    if (confirmed) {
      deleteHolidayRequest(id)
        .then((res) => {
          setHolidayRequests(
            holidayRequests.filter(item =>
              item.id !== res.data.id
            )
          )
        })
        .catch(() => {
          window.alert('通信に失敗しました')
        })
    }
  }

  return (
    <HolidayRequestListArea>
      <Top>
        <Section>
          <SelectBox><Select value={selectedYear} onChange={setSelectedYear} options={years} />年</SelectBox>
          <SelectBox><Select value={selectedMonth} onChange={setSelectedMonth} options={months} />月</SelectBox>
          <ButtonBox>
            <ButtonSecondary text='表示する' onClick={handleSubmit} disabled={!selectedYear || !selectedMonth} />
          </ButtonBox>
        </Section>
        <Section>
          <ButtonPrimary path='/employees/requests/holiday' text='新規申請' />
        </Section>
      </Top>
      <Table>
        <TableHead items={heads} />
        <Tbody>
          {holidayRequests.map((holidayRequest, index) => (
            <Row key={index}>
              <TableBody>{holidayRequest.created_at}</TableBody>
              <TableBody>{holidayRequest.date}</TableBody>
              <TableBody>{holidayRequest.status}</TableBody>
              <TableBody>{holidayRequest.kind}</TableBody>
              <TableBody>{holidayRequest.reason}</TableBody>
              <TableBody>
                <SBalign>
                  <ButtonRed
                    text="削除"
                    onClick={() => handleDelete(holidayRequest.id)}
                    disabled={holidayRequest.status !== '未承認'}
                  />
                </SBalign>
              </TableBody>
            </Row>
          ))}
        </Tbody>
      </Table>
    </HolidayRequestListArea>
  )
}

const HolidayRequestListArea = styled.div`
  font-size: 16px;
`
const Top = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 80%;
  margin: 0 0 30px;
`
const Section = styled.div`
  display: flex;
`
const SelectBox = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`
const ButtonBox = styled.div`
  margin-left: 50px;
`
const Table = styled.table`
  border-collapse: collapse;
  width: 80%;
  table-layout: fixed;
`
const Tbody = styled.tbody`
  display: contents;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 300px;
`
const Row = styled.tr`
`
const SBalign = styled.div`
  text-align: right;
`

export default HolidayRequestList
