import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import ButtonThirdly from '../../../atoms/share/button/ButtonThirdly';
import ButtonRed from '../../../atoms/share/button/ButtonRed';
import TableHead from '../../../atoms/share/TableHead'
import TableBodyLarge from '../../../atoms/share/table/TableBodyLarge';
import TableButtonArea from '../../../atoms/share/table/TableButtonArea';
import { fetchEmploymentStatuses, deleteEmploymentStatus } from '../../../../api/employment_status'

export const EmploymentStatusList = () => {
  const [employment_statuses, setEmploymentStatuses] = useState([]);

  useEffect(() => {
    fetchEmploymentStatuses()
      .then((res) => {
        setEmploymentStatuses(res.data)
      })
      .catch(() => {
        window.alert('通信に失敗しました')
      });
  }, []);

  const heads = ['雇用形態名', '']

  const handleDelete = (id: number) => {
    const confirmed = window.confirm('雇用形態を削除します。よろしいですか？');

    if (confirmed) {
      deleteEmploymentStatus(id)
        .then((res) => {
          setEmploymentStatuses(
            employment_statuses.filter(item =>
              item.id !== res.data.id
            )
          )
        })
        .catch(() => {
          window.alert('通信に失敗しました')
        });
    }
  }

  return (
    <EmploymentStatusListArea>
      <Table>
        <TableHead items={heads} />
        <Tbody>
          {employment_statuses.map((employment_status, index) => (
            <Row key={index}>
              <TableBodyLarge>{employment_status.name}</TableBodyLarge>
              <TableBodyLarge>
                <TableButtonArea>
                  <ButtonThirdly path={`/management/employment_statuses/${employment_status.id}/edit`} text='編集' />
                  <ButtonRed text='削除' onClick={() => handleDelete(employment_status.id)} />
                </TableButtonArea>
              </TableBodyLarge>
            </Row>
          ))}
        </Tbody>
      </Table>
    </EmploymentStatusListArea>
  )
}

const EmploymentStatusListArea = styled.div`
  margin: 0 0 100px 0;
`
const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
`
const Tbody = styled.tbody`
  display: contents;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 300px;
`
const Row = styled.tr`
`

export default EmploymentStatusList
