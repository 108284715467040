import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import TableHead from '../../../atoms/share/TableHead'
import TableBody from '../../../atoms/share/TableBody'
import ButtonRed from '../../../atoms/share/button/ButtonRed';
import { fetchCompanies, deleteCompany } from '../../../../api/company'
import ButtonThirdly from '../../../atoms/share/button/ButtonThirdly';
import ButtonPrimary from '../../../atoms/share/button/ButtonPrimary';

export const CompaniesList = ({ currentCompanyId }) => {
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    fetchCompanies()
      .then((res) => {
        setCompanies(res.data)
      })
      .catch(() => {
        console.log('通信に失敗しました');
      });
  }, []);

  const heads = ['事業者名', '代表者名', 'メールアドレス', '']

  const handleDelete = (id: number) => {
    const confirmed = window.confirm('管理者アカウントを削除します。よろしいですか？');

    if (confirmed) {
      deleteCompany(id)
        .then((res) => {
          setCompanies(
            companies.filter(item =>
              item.id !== res.data.id
            )
          )
        })
        .catch(() => {
          window.alert('通信に失敗しました')
        });
    }
  }

  return (
    <CompaniesListArea>
      <ButtonBox>
        <ButtonPrimary text='新規登録' path='/management/companies/new' />
      </ButtonBox>
      <Table>
        <TableHead items={heads} />
        <Tbody>
          {companies.map((company, index) => (
            <Row key={index}>
              <TableBody>{company.name}</TableBody>
              <TableBody>{company.staff_name}</TableBody>
              <TableBody>{company.email}</TableBody>
              <TableBody>
                <TableButtonArea>
                  {/* <ButtonFlexBox>
                    <ButtonThirdly path={`/management/${company.id}`} text='詳細' />
                  </ButtonFlexBox> */}
                  <ButtonFlexBox>
                    <ButtonThirdly path={`/management/companies/${company.id}/edit`} text='編集' />
                  </ButtonFlexBox>
                  {companies.length > 1 && currentCompanyId !== company.id && (
                    <ButtonFlexBox>
                      <ButtonRed text='削除' onClick={() => handleDelete(company.id)} />
                    </ButtonFlexBox>
                  )}
                </TableButtonArea>
              </TableBody>
            </Row>
          ))}
        </Tbody>
      </Table>
    </CompaniesListArea>
  )
}

const CompaniesListArea = styled.div`
  margin: 0 0 100px 0;
`
const Table = styled.table`
  border-collapse: collapse;
  margin: 0 auto;
  width: 100%;
  table-layout: auto;
`
const Tbody = styled.tbody`
  display: contents;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 300px;
  border-bottom: 1px solid black;
`
const Row = styled.tr`
`
const ButtonBox = styled.div`
  width: 100%;
  margin: 0 auto;
  text-align: right;
  margin-bottom: 30px;
  font-size: 16px;
`
const TableButtonArea = styled.div`
  display: flex;
  width: 100%;
  justify-content: right;
  text-align: center;
`
const ButtonFlexBox = styled.div`
  margin: 0 1rem;
`

export default CompaniesList
